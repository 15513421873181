<template>
  <div>
    <!--版务-->
    <div id="forum-info">
      <div>
        <b>{{forum.name}}</b>&nbsp;
        {{$t('common.today')}}：<span class="hot-number">{{forum.today_topics}}</span>
        <Icon v-if="forum.today_topics>forum.yesterday" type="md-trending-up" />
        <Icon v-else-if="forum.today_topics<forum.yesterday" type="md-trending-down" />
        &nbsp;|&nbsp;
        {{$t('common.topic')}}：<span class="hot-number">{{forum.topics}}</span>
      </div>
      <div>
        {{$t('common.master')}}：
        <SogaLink><router-link v-for="(master, mi) in forum.masters" :key="mi" :to="'user?id='+master.id" style="margin-right: 3px;">
          <span v-if="master.nick">{{master.nick}}</span>
          <span v-else>{{master.uname}}</span>
        </router-link></SogaLink>
      </div>
      <!--介绍-->
      <div v-if="forum.brief" v-html="forum.brief"></div>
      <!--公告-->
      <div v-if="forum.announcement" v-html="forum.announcement"></div>
    </div>

    <div style="display: table; width: 100%; text-align: left;" >
      <Button v-if="!inSoga && auth.allowthread" @click="newTopic" type="primary" icon="md-add" style="display: table-cell;">
        {{$t('page.forum.btn_topic')}}</Button>
      <SogaPage v-if="inSoga"
                :current="req_page" :total="forum.topic_num" :page-size="page_size" @on-change="page_change"
                :prev-text="label_page_prev" :next-text="label_page_next" class="pager"
                :style="pager_style" style="display: table-cell;"></SogaPage>
      <Page v-else :current="req_page" :total="forum.topic_num" :page-size="page_size" @on-change="page_change"
            :prev-text="label_page_prev" :next-text="label_page_next" size="small" class="pager"
            :style="pager_style" style="display: table-cell;" :transfer="true"
            :show-elevator="!inSoga" show-total/>
    </div>

    <Divider style="margin-top: 10px;"></Divider>
    <!-- 列表 -->
    <Table border :size="table_size" :loading="loading" :no-data-text="loading ? data_loading : no_data_text"
           :columns="topic_header" :data="topic_list"></Table>
    <Divider style="margin-bottom: 10px;"></Divider>

    <div style="display: table; width: 100%; text-align: left;" >
      <Button v-if="!inSoga && auth.allowthread" @click="newTopic" type="primary" icon="md-add" style="display: table-cell;">
        {{$t('page.forum.btn_topic')}}</Button>
      <SogaPage v-if="inSoga"
                :current="req_page" :total="forum.topic_num" :page-size="page_size" @on-change="page_change"
                :prev-text="label_page_prev" :next-text="label_page_next" class="pager"
                :style="pager_style" style="display: table-cell;"></SogaPage>
      <Page v-else :current="req_page" :total="forum.topic_num" :page-size="page_size" @on-change="page_change"
            :prev-text="label_page_prev" :next-text="label_page_next" size="small" class="pager"
            :style="pager_style" style="display: table-cell;" :transfer="true"
            :show-elevator="!inSoga" show-total/>
    </div>

    <Divider style="margin-top: 10px;"></Divider>

    <!-- 发帖 -->
    <Modal v-model="publish_show" :width="publish_width" style="max-width: 100%;" footer-hide>
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-create"></Icon>{{$t('page.forum.lb_publish_topic')}}
      </p>
      <Publish @on-success="onPublished" :fid="forum.id" :tid="0"></Publish>
    </Modal>
  </div>
</template>

<script>
  import { httpGet } from '@/api/data';
  import util from '@/libs/util';
  import Publish from './parts/publish';
  import SogaLink from './parts/soga_link';
  import UserName from './parts/user_name';
  import SogaPage from './parts/soga_page';

  export default {
    name: 'Forum',
    components: {
      SogaLink,
      Publish,
      SogaPage
    },
    data () {
      return {
        inSoga: this.$store.state.inSoga,
        loading: true,
        no_data_text: this.$t('page.nodata'),
        data_loading: this.$t('page.data_loading'),

        pager_style: this.$store.state.inSoga ? 'text-align: center;' : '',
        label_page_prev: this.$t('page.page_prev'),
        label_page_next: this.$t('page.page_next'),
        page_size: 10,
        curr_page: 1,
        req_page: 1,

        topic_order: null,

        table_size: 'default',
        table_header: {
          topic: 200,
          author: 120,
          stats: 75,
          last: 120
        },

        publish_show: false,
        publish_width: '80%',

        forum: {
          title: '',
          des: '',
          yesterday: 0,
          today: 0,
          topic_num: 0,
          masters: [],
          notice: ''
        },
        topic_list: [],

        topic_header: [
          {
            key: 'topic',
            minWidth: 200,
            ellipsis: true,
            render: (h, p) => {
              const style = this.inSoga ? 'font-size:large;' : '';
              const renders = [];
              if (p.row.closed) {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-lock',
                    size: 'small'
                  }
                }));
              } else {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-document-outline',
                    size: 'small'
                  }
                }));
              }
              renders.push(h('router-link', {
                style: style,
                props: {
                  to: {
                    name: 'topic',
                    query: { id: p.row.id },
                    params: { topic: p.row }
                  }
                }
              }, '[' + p.row.flag.name + ']' + p.row.subject));
              if (p.row.has_pic) {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-image'
                  }
                }));
              }
              if (p.row.has_attach) {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-attach'
                  }
                }));
              }
              return renders;
            },
            renderHeader: (h/*, p */) => {
              const style = this.inSoga ? 'font-size:large;' : '';
              const curStyle = style + 'color: #2c3e50;';
              return [
                h('a', {
                  style: this.topic_order ? style : curStyle,
                  on: {
                    click: () => {
                      this.topic_order = null;
                      this.loadTopics();
                    }
                  }
                }, this.$t('page.lh_order_new')),
                h('span', ' '),
                h('a', {
                  style: this.topic_order === 'posts desc' ? curStyle : style,
                  on: {
                    click: () => {
                      this.topic_order = 'posts desc';
                      this.loadTopics();
                    }
                  }
                }, this.$t('page.lh_order_hot'))
              ];
            }
          },
          {
            title: this.$t('page.lh_author'),
            key: 'author',
            width: 150,
            render: (h, p) => {
              return [
                h(UserName, { props: { user: p.row.user } }),
                h('br'),
                h('span', {
                  attrs: {
                    style: 'font-size:x-small;'
                  }
                }, util.showTimeStamp(p.row.created_at, true))
              ];
            }
          },
          {
            title: this.$t('page.lh_stats'),
            key: 'reply',
            width: 75,
            render: (h, p) => {
              return [
                h('span', {
                  attrs: {
                    style: 'font-size:x-small;'
                  }
                }, p.row.posts),
                h('br'),
                h('span', {
                  attrs: {
                    style: 'font-size:x-small;'
                  }
                }, p.row.views)
              ];
            }
          },
          {
            title: this.$t('page.lh_last'),
            key: 'last',
            width: 150,
            render: (h, p) => {
              const user = p.row.last_user;
              if (!user) {
                return '';
              }
              return [
                h(UserName, { props: { user: user } }),
                h('br'),
                h('span', {
                  attrs: {
                    style: 'font-size:x-small;'
                  }
                }, util.showTimeStamp(p.row.updated_at, true))
              ];
            }
          }
        ]
      }
    },
    computed: {
      auth () {
        return util.userAuth(this.$store.state, this.forum);
      }
    },
    methods: {
      initData () {
        let page = this.$route.query.page;
        if (typeof page === 'string') {
          page = parseInt(page);
        }
        if (typeof page === 'number') {
          if (page > 0) { this.req_page = page; }
        }
        if (window.screen.width < 730) {
          this.publish_width = '100%';
        }

        const forum = this.$route.params.forum;
        if (forum) {
          this.forum = forum;
          this.pushNav(forum);
        }
        // 获取数据
        httpGet('/v1/bbs/forum/' + this.$route.query.id)
          .then(res => {
            if (res.masters) {
              res.mids = [];
              for (const i in res.masters) {
                res.mids.push(res.masters[i].id);
              }
            }
            this.forum = res;
            if (!this.auth.allowread) {
              this.topic_list = [];
              this.loading = false;
              this.no_data_text = this.$t('page.no_auth_view');
            }
            this.pushNav(res);
          }).catch(() => {
          });
        if (this.auth.allowread) {
          this.loadTopics();
        } else {
          this.loading = false;
          this.no_data_text = this.$t('page.no_auth_view');
        }
      },
      pushNav (forum) {
        this.$store.commit('nav', [
          [
            forum.name,
            this.$route
          ]
        ]);
      },
      loadTopics () {
        if (!this.auth.allowread) {
          return;
        }
        let url = '/v1/bbs/forum/' + this.$route.query.id + '/topics?pageSize=' + this.page_size + '&page=' + this.req_page;
        if (this.topic_order) {
          url += '&order=' + this.topic_order;
        } else {
          url += '&order=updated_at desc';
        }

        this.loading = true;
        httpGet(url)
          .then(res => {
            if (!this.auth.allowread) {
              this.topic_list = [];
              this.loading = false;
              this.no_data_text = this.$t('page.no_auth_view');
              return;
            }
            this.curr_page = res.current_page;
            this.topic_list = res.data;
            this.forum.topic_num = res.total;
            for (const i in this.topic_list) {
              const post = this.topic_list[i];
              util.checkMaster(post.user, this.forum.mids);
              util.checkMaster(post.last_user, this.forum.mids);
            }
            if (this.curr_page === 1 && this.topic_list.length === 0) {
              this.forum.today_topics = 0;
              this.forum.topics = 0;
              this.forum.posts = 0;
              this.forum.topic_num = res.total;
            }
          }).catch(() => {
          }).finally(() => {
            this.req_page = this.curr_page;
            this.loading = false;
          });
      },
      page_change (page) {
        this.req_page = page;
        this.loadTopics();
      },
      checkLogin (action) {
        if (util.hasLogin(this.$store)) {
          return true;
        }
        if (action) {
          this.$router.push({
            name: 'login',
            params: {
              action: action
            }
          });
        }
        return false;
      },
      newTopic () {
        if (!this.checkLogin('newTopic')) {
          return;
        }
        // 权限检查
        if (!this.auth.allowthread) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: this.$t('page.no_auth_opt')
          });
          return;
        }
        this.publish_show = true;
      },
      onPublished (res) {
        /* this.req_page = Math.ceil((this.forum.topic_num + 1) / this.page_size);
        this.loadTopics(); */
        this.publish_show = false;
        // 跳转到topic
        this.$router.push({
          name: 'topic',
          query: { id: res.id },
          params: { topic: res }
        });
      }
    },
    created () {
      if (window.screen.width < 730) {
        this.table_size = 'small';
        this.label_page_prev = '<';
        this.label_page_next = '>';
        this.topic_header[1].width = 100;
        this.topic_header[3].width = 100;
      }
    },
    mounted () {
      this.initData();
      if (this.$route.params.action === 'newTopic' && this.checkLogin()) {
        // 权限检查
        if (!this.auth.allowthread) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: this.$t('page.no_auth_opt')
          });
          return;
        }
        this.publish_show = true;
      }
    }
  };
</script>

<style scoped>
  #forum-info {
    border: lightblue solid 1px;
    padding: 5px;
    text-align: left;
    margin-bottom: 10px;
  }
  .pager {
    display: table-cell;
    text-align: right;
    vertical-align: bottom;
  }
</style>
